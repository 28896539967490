import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import Papa from "papaparse"
import JsBarcode from 'jsbarcode';
import nameMaster from "../assets/nameMaster4.json"

export default function YarnTabOne({ inboundData, multiChecked, handleOne }) {
  const [base64Images, setBase64Images] = useState([]);
  const [data, setData] = useState([]);
  const [po, setPo] = useState("HC1013MNW");
  const [ro, setRo] = useState("4360393");
//   const [quantity, setQuantity] = useState("40");



  // let sku = "1-3333X1-4444X1-6666X1-9999X1-2222X1-22222"
  let weight = "8.85 KG"
  // let upc = "810118930423"
  // let po = "PACA-92823"
  // let quantity = "30"
  let dim = "22 x 15 x 10.5 (in)"


  //FILE UPLOAD
  const handleFileUpload = (e) => {
    let currentTime = new Date().toLocaleString()
    console.log(currentTime)
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function(results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map(elem => {
            let foundName = nameMaster.find((elm) => elm.SKU === elem.sku)

            if (foundName) {

            
           
  
            return (
              {
                sku: elem.sku,
                upc: elem.upc,
                quantity: elem.quantity,
                des: foundName.Description ?? ""
                   //SCHEELS
                  //  cat: elem.cat,
                  //  size: elem.size,
                  //  color: elem.color
              }
            )
            } else {
                return (
                    {
                        sku: elem.sku,
                        upc: elem.upc,
                        des: "",
                        quantity: elem.quantity,

                        //SCHEELS
                        // cat: elem.cat,
                        // size: elem.size,
                        // color: elem.color
                    }
                )
            }
  
      
          })
          console.log(formArray)
          setData(formArray)
        }}
      )
    }
  
  }
  const createBarcode = (upc) => {
    const canvas = document.createElement('canvas');
    canvas.width = 250;
    canvas.height = 110;
    console.log("TEST")
  
    JsBarcode(canvas, upc, {format: "UPC"});
    return canvas.toDataURL("image/png");
  }

  const handleImageLoad = async ({
   sku,
   upc,
   des,
   quantity,
   cat,
   size,
   color
  }) => {
    const canvas = document.createElement('canvas');
    canvas.width = 600;
    canvas.height = 400;
    const ctx = canvas.getContext('2d');

    
    // const img = new Image();
    // img.crossOrigin = 'anonymous';
    // console.log("canvas created")

   

    await new Promise((resolve) => {
  
          
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'black';

      // ctx.beginPath();
      // ctx.rect(10, 10, 165, 40);
      // ctx.fill();
      // ctx.stroke();

   
      let barImg = new Image()
      barImg.onload = () => {
        console.log("bar")

          ctx.drawImage(barImg, 290, 180, 250, 110)

          ctx.font = "40px Helevetica";
          ctx.fillStyle = "black";

          //COMPANY
          ctx.fillText("PACAS", 220, 40)

          ctx.font = "20px Helevetica";

          //SKU
          ctx.fillText("SKU: ",40,75)
          ctx.fillText(sku,100,75)

        //DES
        ctx.fillText("DES: ",40,105)
        ctx.fillText(des,100,105)

          //PO
          ctx.fillText("PARTIAL ",40, 140)
          // ctx.fillText(po,100, 140)

          //RO
          ctx.fillText("QUANTITY: ",40, 160)
          // ctx.fillText(ro,100, 160)

          //QTY

          ctx.fillText("QTY: ",40, 190)
          ctx.fillText(quantity,100, 190)

          //Net Weight

          ctx.fillText("N.W. ",40, 240)
          ctx.fillText(weight,100, 240)

          //DIM

          ctx.font = "15px Helevetica";

          ctx.fillText("Dim: ",40, 300)
          ctx.fillText(dim,100, 300)

          ctx.font = "20px Helevetica";

          //UPC
          ctx.fillText("UPC#: ",300, 140)
          ctx.fillText(upc,400, 140)


          ctx.lineWidth = 2.5;

          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 50); // Move the pen to (30, 50)
          ctx.lineTo(550, 50); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
        //Borders
        ctx.beginPath(); // Start a new path
        ctx.moveTo(35, 85); // Move the pen to (30, 50)
        ctx.lineTo(550, 85); // Draw a line to (150, 100)
        ctx.stroke(); // Render the path
          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 120); // Move the pen to (30, 50)
          ctx.lineTo(550, 120); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 170); // Move the pen to (30, 50)
          ctx.lineTo(550, 170); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 340); // Move the pen to (30, 50)
          ctx.lineTo(550, 340); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 210); // Move the pen to (30, 50)
          ctx.lineTo(280, 210); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 260); // Move the pen to (30, 50)
          ctx.lineTo(280, 260); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //

          //Borders VERT 1
          ctx.beginPath(); // Start a new path
          ctx.moveTo(35, 50); // Move the pen to (30, 50)
          ctx.lineTo(35, 340); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders VERT 2
          ctx.beginPath(); // Start a new path
          ctx.moveTo(90, 50); // Move the pen to (30, 50)
          ctx.lineTo(90, 120); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
               //Borders VERT 2
               ctx.beginPath(); // Start a new path
               ctx.moveTo(90, 170); // Move the pen to (30, 50)
               ctx.lineTo(90, 340); // Draw a line to (150, 100)
               ctx.stroke(); // Render the path
          //
          //Borders VERT 3
          ctx.beginPath(); // Start a new path
          ctx.moveTo(280, 120); // Move the pen to (30, 50)
          ctx.lineTo(280, 340); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
          //Borders VERT 4
          ctx.beginPath(); // Start a new path
          ctx.moveTo(550, 50); // Move the pen to (30, 50)
          ctx.lineTo(550, 340); // Draw a line to (150, 100)
          ctx.stroke(); // Render the path
          //
      
      

        //   setImage(canvas.toDataURL('image/png'))

        resolve();
      

      }
   


          
        

    //   ctx.fillStyle = hex;
    //   ctx.fillRect(540, 0, canvas.width, canvas.height);

        
        // img.src = data;
        barImg.src = createBarcode(upc)
    });


    //Bullshit

    // await new Promise((resolve) => {
  
          
    //   ctx.fillStyle = 'white';
    //   ctx.fillRect(0, 0, canvas.width, canvas.height);
    //   ctx.fillStyle = 'black';

    //   // ctx.beginPath();
    //   // ctx.rect(10, 10, 165, 40);
    //   // ctx.fill();
    //   // ctx.stroke();

   
    //   let barImg = new Image()
    //   barImg.onload = () => {
    //     console.log("bar")

    //       ctx.drawImage(barImg, 40, 275, 250, 110)

    //       ctx.font = "40px Helevetica";
    //       ctx.fillStyle = "black";

    //       //COMPANY
    //       // ctx.fillText("PACAS", 220, 40)

        

    //       //SKU
    //       ctx.font = "15px Helevetica";
    //       ctx.fillText("FROM: ",40,45)
    //       ctx.fillText("TO: ",40,80)
    //       ctx.fillText("PO #: ",40, 120)
    //       ctx.fillText("Style: ",40, 145)
    //       ctx.fillText("Color: ",40, 170)
    //       ctx.fillText("Size: ",40, 195)
    //       ctx.fillText("Style Des: ",40, 220)
    //       ctx.fillText("Qty: ",40, 245)
    //       ctx.fillText("UPC: ",40, 270)


    //       ctx.font = " bold 15px Helevetica";



    //       const comName = "Pacas Inc."
    //       const fromLoc = "39 Chestnut Ave, Westmont, IL 60559"
    //       ctx.fillText(comName,115,45)
    //       ctx.fillText(fromLoc,115,61)

    //     //DES
     

    //       const comName2 = "Grand Forks Scheels"
    //       const fromLoc2 = "2800 South Columbia Road, Grand Forks, ND 58201"


    //     ctx.fillText(comName2,115,80)
    //     ctx.fillText(fromLoc2,115,96)

    //       //PO

    //       ctx.fillText("18907592",115, 120)

      

    //       //QTY

     
    //       ctx.fillText(sku,115, 145)

    //       //Net Weight

   
    //       ctx.fillText(color,115, 170)


      
    //       ctx.fillText(size,115, 195)

      
    //       ctx.fillText(cat,115, 220)


       
    //       ctx.fillText(quantity,115, 245)

    //       //DIM

    //       // ctx.font = "15px Helevetica";

    //       // ctx.fillText("Dim: ",40, 300)
    //       // ctx.fillText(dim,100, 300)

    //       // ctx.font = "20px Helevetica";

    //       //UPC

  
    //       ctx.fillText(upc,115, 270)


    //       ctx.lineWidth = 2.5;

    //       //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 50); // Move the pen to (30, 50)
    //     //   ctx.lineTo(550, 50); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     // //Borders
    //     // ctx.beginPath(); // Start a new path
    //     // ctx.moveTo(35, 85); // Move the pen to (30, 50)
    //     // ctx.lineTo(550, 85); // Draw a line to (150, 100)
    //     // ctx.stroke(); // Render the path
    //     //   //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 120); // Move the pen to (30, 50)
    //     //   ctx.lineTo(550, 120); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 170); // Move the pen to (30, 50)
    //     //   ctx.lineTo(550, 170); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 340); // Move the pen to (30, 50)
    //     //   ctx.lineTo(550, 340); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 210); // Move the pen to (30, 50)
    //     //   ctx.lineTo(280, 210); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 260); // Move the pen to (30, 50)
    //     //   ctx.lineTo(280, 260); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //

    //     //   //Borders VERT 1
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(35, 50); // Move the pen to (30, 50)
    //     //   ctx.lineTo(35, 340); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders VERT 2
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(105, 50); // Move the pen to (30, 50)
    //     //   ctx.lineTo(105, 120); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //        //Borders VERT 2
    //     //        ctx.beginPath(); // Start a new path
    //     //        ctx.moveTo(90, 170); // Move the pen to (30, 50)
    //     //        ctx.lineTo(90, 340); // Draw a line to (150, 100)
    //     //        ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders VERT 3
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(280, 120); // Move the pen to (30, 50)
    //     //   ctx.lineTo(280, 340); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
    //     //   //Borders VERT 4
    //     //   ctx.beginPath(); // Start a new path
    //     //   ctx.moveTo(550, 50); // Move the pen to (30, 50)
    //     //   ctx.lineTo(550, 340); // Draw a line to (150, 100)
    //     //   ctx.stroke(); // Render the path
    //     //   //
      
      

    //     //   setImage(canvas.toDataURL('image/png'))

    //     resolve();
      

    //   }
   


          
        

    // //   ctx.fillStyle = hex;
    // //   ctx.fillRect(540, 0, canvas.width, canvas.height);

        
    //     // img.src = data;
    //     barImg.src = createBarcode(upc)
    // });

    const dataURL = canvas.toDataURL('image/png');

    return dataURL;
  };

  const handleMultiple = async () => {
    const filteredArray = data.reduce((uniqueArray, currentObj) => {
      const isDuplicate = uniqueArray.some(obj => obj.sku === currentObj.sku);
      if (!isDuplicate) {
        uniqueArray.push(currentObj);
      }
      return uniqueArray;
    }, []);

    let filterFinal = [] 
    if (multiChecked === true) {
      filterFinal = filteredArray
    } else {
      filterFinal = data
    }
    const promises = filterFinal.map(handleImageLoad);
    const base64Array = await Promise.all(promises);
    console.log("253")
    setBase64Images(base64Array);
  };

  const handleDownload = async () => {
    const zip = new JSZip();
  
    for (let i = 0; i < base64Images.length; i++) {
      const image = new Image();
      image.src = base64Images[i];
      await new Promise(resolve => {
        image.onload = resolve;
      });
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
  
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [9, 6],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 9, 6);
      const pdfBlob = pdf.output('blob');
  
      zip.file("BOX_" + data[i].sku + ".pdf", pdfBlob);
    }

    const date = new Date();
    const currentTime = "BOX_" + (date.getMonth() + 1).toString() + '_' + 
                        date.getDate().toString() 
  
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${currentTime}.zip`;
      link.click();
    });
  };

  
  
    useEffect(() => {
        console.log("fire")
      handleMultiple();
    }, [data]);
  
    return (
        <div style={{marginLeft: "200px"}}>
          <button onClick={() => handleOne()}>Back</button>
            <div style={{display:"flex", marginLeft: "5vw"}}>
          <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
        />
      {data.length > 0 && <button style={{marginLeft:"40vw"}} onClick={handleDownload}>Download</button>}
</div>
      <div style={{ height: "100vh", overflowY: "scroll",marginBottom: "40px" }} className="pack-display">
          {/* <button onClick={handleDownload}>Download</button> */}
          <br></br>
          {base64Images.map((base64Image, index) => (
          <img key={index} src={base64Image} style={{maxWidth: "600px", border: "2px solid black", marginLeft: "5vw", backgroundColor: "white", }} alt={`Image ${index+1}`} />
      ))}
  
      </div>
      </div>
  )
  }