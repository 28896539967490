
import React, { useState, useEffect }  from "react"
import axios from "axios"

//MUI
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CSTable from "../subViews/CSTable";
import CSTicket from "../subViews/CSTicket";
import TagGraph from "../subViews/TagsGraph";
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';



const tags = [
    'order_status',
    'product_question',
    'website',
    'feedback_positive',
    'feedback_negative',
    'feedback_neutral',
    'discount_code',
    'missing_stalled_order',
    'incorrect_order',
    'order_item_change',
    'customer_details_change',
    'returns_exchanges',
    'suggestion',
    'va',
    'other',
   
  ];


  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "white",
      backgroundColor: "white"
    },
  },
};






export default function CustomerSupport() {
    const [includedTags, setIncludedTags] = useState([])
    const [excludedTags, setExcludedTags] = useState([])
    const [masterData, setMasterData] = useState([])
    const [ticketData, setTicketData] = useState([])
    const [view, setView] = useState(0);
    const [currentIndex, setCurrentIndex] = useState("");
    const [tagData, setTagData] = useState([]);
    const [load, setLoad] = useState(true);
    const [updateTime, setUpdateTime] = useState("");



const handleIndexChange = (newIndex) => {

    setCurrentIndex(newIndex)


}


const nextIndex = () => {
    setCurrentIndex(currentIndex + 1)
}



const handleIncludedTags = (e) => {

    setIncludedTags(e.target.value)

}


const handleExcludedTags = (e) => {



    setExcludedTags(e.target.value)


    const filteredData = masterData.filter((elem) =>
        !e.target.value.some((excludedTag) => elem.tags.includes(excludedTag))
    )


    setTicketData(filteredData)

    

}


const handleLog = () => {
    console.log(includedTags)
}

const handleView = (id) => {
    console.log(id)

    const index = ticketData.findIndex(elem => elem.id === id)

    setCurrentIndex(index)
    setView(1)
}


const handleBack = () => {

    setView(0)
}


const getAllTickets = async () => {
    setLoad(true)
    const response = await axios.post("/api/zendesk/getalltickets")

    console.log(response.data)

    const untaggedCount = response.data.filter((elem) => elem.tags.length === 0)
    console.log(untaggedCount.length)




    const tagArray = response.data
    .filter((elem) => elem.tags.length !== 0)
    .flatMap((elem) => elem.tags)
    console.log(tagArray)

    const tc  = tagArray.reduce((prev, next) => {
        prev[next] = (prev[next] || 0) + 1

        return (prev)
    }, {})

   

    console.log(tc)


    const tagsA = Object.entries(tc).map(([key, val]) => ({
        label: key,
        value: val
    }))


    const tagsForm = tagsA.filter((elem) => tags.includes(elem.label))



    updateTagData(tagsForm)

    setTagData(tagsForm)

    setLoad(false)


    
}


const updateTagData = async (tags) => {

    const now = new Date();

    const options = {
        timeZone: "America/Chicago", // Central Time Zone
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true, // Use 12-hour format with AM/PM
    };

    // Format the date and time
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(now);

    // Extract parts for custom formatting
    const date = `${parts.find(part => part.type === 'month').value}/` +
                 `${parts.find(part => part.type === 'day').value}/` +
                 `${parts.find(part => part.type === 'year').value}`;

    const time = `${parts.find(part => part.type === 'hour').value}:` +
                 `${parts.find(part => part.type === 'minute').value}` +
                 `${parts.find(part => part.type === 'dayPeriod').value.toLowerCase()}`;

    const response =  await axios.post("/api/tag/upload",
        {
            time: `${date} ${time}`,
            tags: tags
        }
    )

    console.log(response)
}


const getTagTickets = async () => {
    if (includedTags.length > 0) {
        setLoad(true)
        const response = await axios.post("/api/zendesk/getbytag", 
            {
                tags: includedTags
            }
        )
    
        console.log(response.data)

        const filteredData = response.data.filter((elem) =>
            !excludedTags.some((excludedTag) => elem.tags.includes(excludedTag))
        );

        console.log(filteredData)

        setTicketData(filteredData)
        setMasterData(response.data)
        setLoad(false)


    } else {
        alert("Select a Tag")
    }

}


const getTagData = async () => {

    const response = await axios.post("/api/tag/getone")

    setUpdateTime(response.data.time)
    setTagData(response.data.tags)

    setLoad(false)
}


useEffect(() => {
    getTagData()

}, [])


const getSingleTicket = async () => {
    const response = await axios.post("/api/zendesk/getbyid",
        {
            id: "383703"
        }
    )

    console.log(response.data)

    const messageContentArray = response.data.comments.map((elem, i) => {

        return (
            `**Message ${i + 1}**: ${elem.body}`
        )
    })

    const joinedMessage =messageContentArray.join(" ")

    console.log(joinedMessage)
}









    return (
        <div style={{ marginLeft: "14vw" }}>

            {view === 0 &&
        
            <div>

            
            
                <FormControl style={{marginRight:"2vw"}} sx={{  width: 300 }}>
                <InputLabel sx={{color:"white"}}  id="demo-multiple-checkbox-label">Included Tags</InputLabel>
                <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={includedTags}
                onChange={handleIncludedTags}
                input={<OutlinedInput label="Included Tags" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                sx={{ color: "white", backgroundColor: "black"}}
                >
                   <MenuItem
                        sx={{
                            color: "white",
                            backgroundColor: "black",
                            ":hover": {
                              color: "white",
                              backgroundColor: "gray", // Optional hover background color
                            },
                            "&.Mui-selected": {
                              color: "white", // Ensure text stays white when selected
                              backgroundColor: "darkgray", // Optional selected background color
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: "gray", // Adjust hover effect for selected items
                            },
                          }}
                        onClick={() => {
                            if (includedTags.length === tags.length) {
                            setIncludedTags([]);
                            } else {
                            setIncludedTags(tags);
                            }
                        }}
                        >
                        <Checkbox checked={includedTags.length === tags.length} indeterminate={includedTags.length > 0 && includedTags.length < tags.length} />
                        <ListItemText
                            primary={includedTags.length === tags.length ? "Deselect All" : "Select All"}
                        />
                        </MenuItem>
                {tags.map((tag) => (
                    <MenuItem 
                    sx={{
                        color: "white",
                        backgroundColor: "black",
                        ":hover": {
                          color: "white",
                          backgroundColor: "gray", // Optional hover background color
                        },
                        "&.Mui-selected": {
                          color: "white", // Ensure text stays white when selected
                          backgroundColor: "darkgray", // Optional selected background color
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "gray", // Adjust hover effect for selected items
                        },
                      }} key={tag} value={tag}>
                    <Checkbox checked={includedTags.includes(tag)} />
                    <ListItemText primary={tag} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>


            <FormControl style={{marginRight:"2vw"}} sx={{  width: 300 }}>
                <InputLabel sx={{color:"white"}} id="demo-multiple-checkbox-label">Excluded Tags</InputLabel>
                <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={excludedTags}
                onChange={handleExcludedTags}
                input={<OutlinedInput label="Excluded Tags" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                sx={{ color: "white", backgroundColor: "black"}}
                >
                <MenuItem
                 sx={{
                    color: "white",
                    backgroundColor: "black",
                    ":hover": {
                      color: "white",
                      backgroundColor: "gray", // Optional hover background color
                    },
                    "&.Mui-selected": {
                      color: "white", // Ensure text stays white when selected
                      backgroundColor: "darkgray", // Optional selected background color
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "gray", // Adjust hover effect for selected items
                    },
                  }}
                onClick={() => {
                    if (excludedTags.length === tags.length) {
                    setExcludedTags([]);
                    } else {
                    setExcludedTags(tags);
                    }
                }}
                >
                <Checkbox checked={excludedTags.length === tags.length} indeterminate={excludedTags.length > 0 && excludedTags.length < tags.length} />
                <ListItemText
                    primary={excludedTags.length === tags.length ? "Deselect All" : "Select All"}
                />
                </MenuItem>
                {tags.map((tag) => (
                    <MenuItem 
                    sx={{
                        color: "white",
                        backgroundColor: "black",
                        ":hover": {
                          color: "white",
                          backgroundColor: "gray", // Optional hover background color
                        },
                        "&.Mui-selected": {
                          color: "white", // Ensure text stays white when selected
                          backgroundColor: "darkgray", // Optional selected background color
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "gray", // Adjust hover effect for selected items
                        },
                      }}
                    key={tag} value={tag}>
                    <Checkbox checked={excludedTags.includes(tag)} />
                    <ListItemText primary={tag} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>

            {/* <button onClick={getSingleTicket}>LOG</button> */}
            <Button style={{height:"6.65vh", backgroundColor:"#e3e3e3"}} variant="outlined" onClick={getTagTickets}>Get Tickets</Button>

            {!load

            ?
            
            



            (ticketData.length === 0)
             ?
             <div>
             <h6 style={{color:"white"}}>Last Update: {updateTime} </h6>
             <Button onClick={getAllTickets}>Update</Button>
             <TagGraph tagData={tagData} />
           
             </div>
             :
             <CSTable ticketData={ticketData} handleView={handleView} />
            
            

            :
            <div
            style={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              height: "75vh", // Full viewport height for centering
              width: "75vw"
            }}
          >
            <CircularProgress size={150} color="inherit" />
          </div>

        }

        
        

        </div>

        }

        {view === 1 &&
        
        <CSTicket ticketData={ticketData} currentIndex={currentIndex} nextIndex={nextIndex} handleIndexChange={handleIndexChange} handleBack={handleBack} />
        }


  
    </div>

    )
}