
import React, { useState, useEffect }  from "react"
import axios from "axios"

import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { useTooltip, TooltipWithBounds } from "@visx/tooltip";
import { Bar } from "@visx/shape";
import { scaleBand, scaleLinear, scaleTime } from "@visx/scale";
import { curveMonotoneX } from "@visx/curve";
import { LinePath } from "@visx/shape";
import { AxisLeft } from "@visx/axis";
import { Text } from '@visx/text'; 



export default function TagGraph( { tagData }) {
        const [activeBar, setActiveBar] = useState(null);

      const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft } =
          useTooltip();


    const getColor = (index) => {
        const colors = [
          "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#33FFF1",
          "#FF5733", "#FFC300", "#DAF7A6", "#900C3F", "#581845",
          "#28B463", "#3498DB", "#9B59B6", "#F39C12", "#1ABC9C",
          "#2ECC71", "#E74C3C", "#D35400", "#7D3C98", "#2980B9",
          "#8E44AD", "#C0392B", "#16A085", "#F4D03F", "#2C3E50",
          "#27AE60", "#E67E22", "#AF7AC5", "#5DADE2", "#48C9B0",
          "#A569BD", "#F1948A", "#F7DC6F", "#7FB3D5", "#F0B27A",
          "#B03A2E", "#2E86C1", "#F5B041", "#117864", "#82E0AA",
          "#34495E", "#ABB2B9", "#DC7633", "#F9E79F", "#C70039",
          "#581845", "#900C3F", "#00FF7F", "#FF1493", "#40E0D0"
        ];
        return colors[index % colors.length];
      };


        //Bar Chart
    
        const barWidth = 1100;
        const barHeight = 500;
    
    const margin = { top: 20, bottom: 125, left: 30, right: 20 }
    
    
    const xMax = barWidth - margin.left - margin.right;
    const yMax = barHeight - margin.top - margin.bottom;
    
    const xScale = scaleBand({
      range: [0, xMax],
      domain: tagData.map((d) => d.label),
      padding: 0.2
    });
    
    const yScale = scaleLinear({
      range: [yMax, 0],
      domain: [0, Math.max(...tagData.map((d) => typeof d.value === 'number' ? d.value : 0))]
    });



    const BarChart = () => (
        <div>
           <svg
            width={barWidth}
            height={barHeight}
            onMouseLeave={() => {
                setActiveBar(null);
                hideTooltip(); // Hide the tooltip when the mouse leaves the graph
            }}
            >
            <Group top={margin.top} left={margin.left}>
              {tagData.map((d, index) => {
                const barWidth2 = xScale.bandwidth();
                const barHeight2 = yMax - yScale(typeof d.value === 'number' ? d.value : 0);
                const barX = xScale(d.label);
                const barY = yMax - barHeight2;
      
                return (
                  <Group key={`bar-${index}`}>
                    {/* Render Bar */}
                    <Bar
                      x={barX}
                      y={barY}
                      width={barWidth2}
                      height={barHeight2}
                      fill={getColor(index)}
                      onMouseEnter={(event) => {
                        const { pageX, pageY } = event;
                        setActiveBar(d);
                        showTooltip({
                          tooltipData: `${d.label}: ${d.value}`,
                          tooltipTop: pageY - 10,
                          tooltipLeft: pageX + 10
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveBar(null);
                        hideTooltip();
                      }}
                    />
      
                    {/* Add Diagonal Labels */}
                    <Text
                      x={barX + barWidth2 / 2} // Center text relative to bar
                      y={yMax + 70} // Position text below the chart
                      textAnchor="middle" // Center-align the text
                      fontSize={12}
                      fill="white" // Adjust font size
                      transform={`rotate(-45, ${barX + barWidth2 / 2}, ${yMax + 70})`} // Rotate around the text's center
                    >
                      {d.label}
                    </Text>
                  </Group>
                );
              })}
            </Group>
          </svg>
      
          {/* Tooltip */}
          {tooltipData && (
            <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
              {tooltipData}
            </TooltipWithBounds>
          )}
        </div>
      );



   





    return (
        <div >

        <BarChart />
  
        </div>

    )
}