import React, { useState, useEffect }  from "react"
import axios from "axios"

//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Switch from '@mui/material/Switch';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DiscountIcon from '@mui/icons-material/Discount';
import CSDiscountModal from "./CSDiscountModal";
import CSImageModal from "./CSImageModal";






export default function CSTicket( { ticketData, currentIndex, nextIndex, handleBack } ) {

    const [messageContent, setMessageContent] = useState("")
    const [responseContent, setResponseContent] = useState("")
    const [summaryContent, setSummaryContent] = useState("")
    const [refineContent, setRefineContent] = useState("")
    const [currentEmail, setCurrentEmail] = useState("")
    const [currentOrderName, setOrderName] = useState("")
    const [currentTicketDate, setCurrentTicketDate] = useState("")
    const [currentTicketId, setCurrentTicketId] = useState()
    const [expanded, setExpanded] = useState('panel1');
    const [customerCurrentIndex, setCustomerCurrentIndex] = useState(0);
    const [customerTicketsArray, setCustomerTicketsArray] = useState([]);
    const [onMain, setOnMain] = useState(true);
    const [s3Checked, setS3Checked] = useState(true);
    const [currentOrderDetails, setCurrentOrderDetails] = useState([])
    const [imageData, setImageData] = useState([])
    const [sessionContext, setSessionContext] = useState("")


    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };


      const handleS3Checked = (event) => {
        setS3Checked(event.target.checked);
      };


const handleSubNext = () => {
    setOnMain(false)
    setSummaryContent("")
    setResponseContent("")
    setRefineContent("")
    setCurrentTicketDate("")
    setCurrentEmail("")
    setOrderName("")
    setExpanded("panel1")
    setCustomerCurrentIndex((elem) => (elem < customerTicketsArray.length - 1) ? elem + 1 : customerTicketsArray.length - 1 )
}

const handleSubPrevious = () => {
    setOnMain(false)
    setSummaryContent("")
    setResponseContent("")
    setRefineContent("")
    setCurrentTicketDate("")
    setCurrentEmail("")
    setOrderName("")
    setExpanded("panel1")
    setCustomerCurrentIndex((elem) => (elem > 0 ) ? elem - 1 : 0 )
}


const handleSessionContext = (e) => {
    setSessionContext(e.target.value)
}

const addSessionContext = () => {
    getResponse(messageContent)
}



const handleIAR = () => {

    const IARMacro = 
    `Hi!,

I just reached out to you in another thread, If you didn't recieve it, please let me know and I will resend here!

Thanks,
Lyonette

    `

    setResponseContent(IARMacro)
    setS3Checked(false)


}


//Change to rel TEST
    const respondToTicket = async () => {
        if (responseContent !== "") {

            const response = await axios.post("/api/zendesk/respondtoticket", 
                {
                    response: responseContent,
                    id: onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id
                }
            )


            return (response.data)
        }


    }


//Change to Rel TEST
    const getTicketByEmail = async (email) => {

        const response = await axios.post("/api/zendesk/getticketsbyemail", 
            {
                email: email
            }
        )


        console.log(response.data)
        setCustomerTicketsArray(response.data)
        const checkValue = onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id

        const index = response.data.findIndex(elem => elem.id === checkValue)
        setCustomerCurrentIndex(index)
    }
    
    const handleNext = () => {

        if (customerTicketsArray.length === 1) {
        nextIndex()

        setOnMain(true)
        setCustomerCurrentIndex(0)
        setCustomerTicketsArray([])
        setSummaryContent("")
        setResponseContent("")
        setRefineContent("")
        setCurrentTicketDate("")
        setCurrentEmail("")
        setOrderName("")
        setExpanded("panel1")
        setS3Checked(true)

        } else {


                setCustomerTicketsArray((elem) => elem.splice(customerCurrentIndex, 1))

        }
    }

    const getByName = async () => {

        const response = await axios.post("/api/shopify/getbyname", 
            {
                orderName: currentOrderName
            }

        )

        console.log(response.data)

        setCurrentOrderDetails(response.data)

        const tracking = await getfulfillment(response.data.orders[0].id)
        

        if (response.data.orders) {

        return(
            {
                confirmed: true,
                tracking: tracking,
                date: response.data.orders[0].created_at
            }
        ) 
    } else {
        return(
            {
                confirmed: false
              
            }
        ) 

    }
    }


    const handleDiscount = () => {


    }

    const handleTracking = async () => {

        const noOrderFound = `No order found, ask for order number`
        const noFulfillmentFound = `No Fulfillment found, order in process`
        const deadOrder = `Dead Order`
        const regex = /^#[6-9][0-9]{5}$/;

        const checkForDead = (oDate) => {
            const inputDate = new Date(oDate);
            const currentDate = new Date();
            const timeDifference =  currentDate - inputDate;
    
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

            console.log(daysDifference)
        
            return daysDifference >= 15;

        }

        if (currentOrderName !== "" && regex.test(currentOrderName) ) {
            const tracking = await getByName()
            if (tracking.tracking.fulfillments.length !== 0) {
                const fulfillmentDetails = 
                `
                Delievery Status: ${tracking.tracking.fulfillments[0].shipment_status}
                Tracking URL: ${tracking.tracking.fulfillments[0].tracking_url}
                
                `
    
                setRefineContent((elem) => elem + fulfillmentDetails)
            }
            else if (checkForDead(tracking.date)) {
                setRefineContent((elem) => elem + deadOrder + " Date: " + tracking.date)
            }
            
            
            else {
                setRefineContent((elem) => elem + noFulfillmentFound + " Date: " + tracking.date)
            }
        } else {
            const tracking = await getOrdersByEmail()
        if (tracking.confirmed === true) {
            if (tracking.tracking.fulfillments.length !== 0 ) {
                const fulfillmentDetails = 
                `
                Delievery Status: ${tracking.tracking.fulfillments[0].shipment_status}
                Tracking URL: ${tracking.tracking.fulfillments[0].tracking_url}
                
                `
    
                setRefineContent((elem) => elem + fulfillmentDetails)
            }
            else if (checkForDead(tracking.date)) {
                setRefineContent((elem) => elem + deadOrder)
            }
            
            
            else {
                setRefineContent((elem) => elem + noFulfillmentFound)
            }
        } else {
            setRefineContent((elem) => elem + noOrderFound)
        }

       
    }
    setExpanded("panel3")
    }


    const handleS3Payload = async () => {

        const unixTimestamp = Math.floor(Date.now() / 1000).toString()

        const cs = await respondToTicket()

        console.log(cs)

        if (s3Checked) {

        const response =  await axios.post("/api/s3/uploadfile",
            {
                fileName: unixTimestamp,
                payload: {message: messageContent, response: responseContent}
            }
        )

        console.log(response)
      }


        alert("Success")
        handleNext()
    }


    const addSig = () => {

            console.log("Fire")
            const sig = `\n\nWarm Regards, \nLyonette`

            const sigs = [
                `\n\nWarm Regards, \nLyonette`,
                `\n\nThanks, \nLyonette`,

            ]


            setResponseContent((res) => res + sig)


    }


    const getOrdersByEmail = async () => {
        const response = await axios.post("/api/shopify/getbyemail", {
            email: currentEmail
        })

        console.log(response.data)
        if (response.data.orders.length > 0) {

        setCurrentOrderDetails(response.data)

        console.log(response.data.orders[0].id)
        const tracking = await getfulfillment(response.data.orders[0].id)


       

        return(
            {
                confirmed: true,
                tracking: tracking,
                date: response.data.orders[0].created_at
            }
        ) 
    } else {
        return(
            {
                confirmed: false
              
            }
        ) 

    }

    }


//Change for Rel ticket TEST
    const handleRetag = async () => {
        const tag = `dead_order`
        const response = await axios.post("/api/zendesk/retag" , {
            id: onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id,
            tag: tag
        })

        console.log(response.data)

        handleNext()
    }

  

    const getfulfillment = async (id) => {
        console.log(id)
        const response = await axios.post("/api/shopify/getfulfillment",
            {
                id: id
            }
        )

        console.log(response.data)

      return (response.data)
    }


    const addContext = async () => {
            console.log(responseContent)

            setSummaryContent("")
            setResponseContent("")

        const twoShot = 
        `
        **Original Message:**  ${messageContent}
        **Original Response:** ${responseContent}
        **Additional Information:** ${refineContent}
        
        
        `

        console.log(twoShot)


        const response = await axios.post("/api/csresponse/response", 
            {
                message: twoShot
            }
        )

        console.log(response)

        setSummaryContent(response.data.summary)
        setResponseContent(response.data.message_response)
        setRefineContent("")



    }

    const handleRefineInput = (e) => {
        setRefineContent(e.target.value)
    }

    const getEmail = async () => {
        const response = await axios.post("/api/zendesk/getemail", 
            {
                id: ticketData[currentIndex].requester_id
                
            }
        )

        console.log(response.data)

        setCurrentEmail(response.data.user.email)
           if (customerTicketsArray.length === 0) {
            getTicketByEmail(response.data.user.email)
        }

    }




    const getSingleTicket = async () => {

     
        if (currentIndex !== "") {

        
        const response = await axios.post("/api/zendesk/getbyid",
            {
                id: onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id
            }
        )
    
        console.log(response.data)

        setCurrentTicketDate(response.data.comments[0].created_at)

    
        const messageContentArray = response.data.comments.map((elem, i) => {
    
            return (
                `**Message ${i + 1}**: ${elem.body}`
            )
        })
    
        const joinedMessage = messageContentArray.join(" ")
    
        console.log(joinedMessage)

        const concatMessage = `Subject: ${onMain ? ticketData[currentIndex].raw_subject : customerTicketsArray[customerCurrentIndex].raw_subject}, ${joinedMessage}`

        const urlArray = extractImageUrls(concatMessage)

        setImageData(urlArray)


        setMessageContent(concatMessage)

        getResponse(concatMessage)
     }
    }

    const extractImageUrls = (input) => {
        const urlPattern = /https:\/\/pacas\.zendesk\.com\/attachments\/token\/[a-zA-Z0-9]+\/\?name=[^ ]+\.(jpeg|jpg|png|gif)/g;
        const matches = input.match(urlPattern);
        return matches ? matches : [];

    }


    const getResponse = async (mc) => {

        const response = await axios.post("/api/csresponse/response", 
            {
                message: mc,
                sessionContext: sessionContext
            }
        )

        console.log(response)

       

        setSummaryContent(response.data.summary)
        setResponseContent(response.data.message_response)
        setOrderName(response.data.order_number)
    }

//Change to rel TEST
    const handleEscalation = async () => {

        const response = await axios.post("/api/zendesk/escalate",
            {
                id: onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id,
                tag: ["escalation", "lyonette"]
            }
        )

        console.log(response.data)
    }


    useEffect(() => {
        getSingleTicket()
        getEmail()
        


    }, [currentIndex, customerCurrentIndex])
  
  
    return (
        <div style={{ marginLeft: "5vw",  overflowY: "scroll",  height: "100vh" }}>



         <div style={{display:"flex"}}>

        <Button onClick={() => handleBack()}>Back</Button>

        {/* <div style={{color:"white"}}>
                {`Email: ${currentEmail} Order Name: ${currentOrderName} Ticket Date: ${currentTicketDate}`}
        </div> */}

        <Button style={{marginLeft:"65vw"}} onClick={() => handleNext()}>Next</Button>

        </div>
      
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
        
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>


        <Paper>
            <Box>
                <h5>Email: {currentEmail} Tickets: {customerCurrentIndex + 1} / {customerTicketsArray.length}</h5>
                <button onClick={handleSubPrevious}>PREV</button>
                <button onClick={handleSubNext}>Next</button>
            </Box>
        </Paper>

            {/* ACC TESTING */}

            <Accordion expanded={expanded === 'panel1'} onChange={handleExpand('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography component="span" sx={{ width: '33%', flexShrink: 0 }}>
           Details
          </Typography>
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            Details of ticket id: {onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ margin: 0, marginBottom: 0, width: "100%", maxWidth: 400, minWidth: 400, maxHeight: 200, minHeight: 200 }}>
              <Paper
                 style={{
                    height: "25vh",
                    padding: "15px",
                    maxHeight: "65vh",
                    overflow: "auto",
                    }}
               elevation={10}>
                <Typography style={{ padding: "5px" }} variant="h7" gutterBottom>
                {`Email: ${currentEmail} Order Name: ${currentOrderName} Ticket Date: ${currentTicketDate}`}
                </Typography>
              </Paper>
            </Box>
        </AccordionDetails>
      </Accordion>


            <Accordion expanded={expanded === 'panel2'} onChange={handleExpand('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography component="span" sx={{ width: '33%', flexShrink: 0 }}>
            Summary
          </Typography>
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            Summary of ticket id: {onMain ? ticketData[currentIndex].id : customerTicketsArray[customerCurrentIndex].id}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ margin: 0, marginBottom: 0, width: "100%", maxWidth: 400, minWidth: 400, maxHeight: 200, minHeight: 200 }}>
              <Paper
                 style={{
                    height: "25vh",
                    padding: "15px",
                    maxHeight: "65vh",
                    overflow: "auto",
                    }}
               elevation={10}>
                <Typography style={{ padding: "5px" }} variant="h7" gutterBottom>
                {summaryContent.length > 0 ? summaryContent:  <CircularProgress color="inherit" size={24} />}
                </Typography>
              </Paper>
            </Box>
        </AccordionDetails>
      </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleExpand('panel3')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                >
                <Typography component="span" sx={{ width: '33%', flexShrink: 0 }}>
                    Context
                </Typography>
                <Typography component="span" sx={{ color: 'text.secondary' }}>
                    Add Context
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
             
                    <Box sx={{ margin: 0, width: "100%", maxWidth: 400, minHeight: "20vh" }}>
                        <Paper elevation={10} sx={{ padding: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                multiline
                                rows={8}
                                value={refineContent}
                                onChange={handleRefineInput}
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                label="Add Context"
                                variant="outlined"
                            />
                            <RefreshIcon
                                onClick={addContext}
                                style={{ cursor: "pointer", marginLeft: 8 }}
                            />
                            </Box>
                        </Paper>
                        </Box>

                </AccordionDetails>
            </Accordion>

            <Paper elevation={10} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                multiline
                                rows={2}
                                value={sessionContext}
                                onChange={handleSessionContext}
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                label="Session Context"
                                variant="outlined"
                            />
                            <RefreshIcon
                                onClick={addSessionContext}
                                style={{ cursor: "pointer", marginLeft: 8 }}
                            />
                            </Box>
                            </Paper>



      
            <Box sx={{margin: 1}}>
            {/* <button onClick={handleTracking}>Tracking Logic</button> */}


                {/* <button onClick={log}>
                    Add Context
                </button> */}
                <div>

                    
                {/* <button onClick={addSig}>Add Signiture</button> */}

                

                {/* <button onClick={handleRetag}>Dead Order</button> */}

                {/* <button onClick={handleEscalation}>escalate</button> */}

                {/* <button onClick={(currentEmail) => getTicketByEmail}>T EMAIL</button> */}


                </div>

            </Box>
          </div>
      
          {/* Second group of boxes (horizontal layout) */}
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
             <Box sx={{ marginLeft: 1, width: "100%", maxWidth: 600, minWidth: 600 }}>
                <Paper
                    style={{
                    height: "25vh",
                    padding: "15px",
                    maxHeight: "65vh",
                    overflow: "auto",
                    }}
                    elevation={10}
                >
                    <Typography variant="h7" gutterBottom>
                    {messageContent.length > 0 ? messageContent:  <CircularProgress color="inherit" size={24} />}
                    </Typography>
                </Paper>
            </Box>
     
            <Box
                sx={{
                    marginLeft: 1,
                    marginTop: 1,
                    width: "100%",
                    maxWidth: 600,
                    minWidth: 600,
                    overflow: "auto",
                    maxHeight: "40vh",
                    minHeight: "40vh",
                }}
                >
            {/* <Paper
                style={{
                    height: "auto", // Let the height adjust dynamically
                    padding: "15px",
                    minHeight: "40vh",
                    overflowY: "auto", 
                }}
                elevation={10}
                >
                 <Typography
                    variant="h7"
                    gutterBottom
                    style={{
                        whiteSpace: 'pre-wrap',
                    }}
                    >
                    {responseContent.length > 0 ? responseContent:  <CircularProgress color="inherit" size={24} />}
                    </Typography>
                            
              </Paper> */}

                    <Paper
                        style={{
                            height: "auto", // Let the height adjust dynamically
                            padding: "15px",
                            minHeight: "40vh",
                            overflowY: "auto", 
                        }}
                        elevation={10}
                    >
                        {responseContent.length > 0 ? (
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                value={responseContent}
                                onChange={(e) => setResponseContent(e.target.value)} // Update state when the content changes
                                variant="outlined"
                            />
                        ) : (
                            <CircularProgress color="inherit" size={24} />
                        )}
                    </Paper>
            </Box>
            <div>
            <Switch
                checked={s3Checked}
                onChange={handleS3Checked}
                inputProps={{ 'aria-label': 'controlled' }}
                />
            <button style={{marginLeft:"12vw"}} onClick={handleS3Payload}>Send Payload</button>
            </div>
          </div>
          <div >
            <Paper style={{width:"3vw"}}>
            <Box style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems:"center" }}>
                <LocalShippingIcon 
                sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }} 
                onClick={handleTracking}
                />
                <PriorityHighIcon 
                sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }}  
                onClick={handleEscalation}
                />
                <ModeEditIcon
                 sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }}
                  onClick={addSig}
                  />
                <ReportProblemIcon
                 sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }}
                 onClick={handleRetag}
                   />
                <CopyAllIcon 
                   sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }}
                   onClick={handleIAR}
                />
                <CSDiscountModal orderDetails={currentOrderDetails} ticketId={ticketData[currentIndex].id} setRefineContent={setRefineContent} />
                {imageData.length !== 0 
                ?
                <CSImageModal imageData={imageData} />
                :
                <div></div>
                }
              
            </Box>
            </Paper>
          </div>
        </div>
      </div>
      
    );
  }