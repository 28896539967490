import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import loading from "../assets/loadingNew.gif"
import axios from "axios"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    height: "40vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function BomStatusModal( { data } ) {

    const [open, setOpen] = useState(false);


    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }




if (1 === 2) {

  return (
    // <div>
    //   <Button onClick={handleOpen}>View</Button>
    //   <Modal
    //     open={createOpen}
    //     onClose={handleCreateClose}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <Box sx={style}>

    //        <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}}src={loading} />
        
    //     </Box>
    //   </Modal>
    // </div>
    <></>
  );
} else if (1 === 1) {

    return (
        <div>
                 <Button variant="outlined" style={{ maxHeight: "4vh", backgroundColor: 'green', color: 'white' }} onClick={handleOpen}>View</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h1">
                {data[0].batch}
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h2">
              Updated By: {data[0].updated_by}
              <br></br>
                
               Updated At: {data[0].updated_at}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               Notes:
               <br></br>
                {data[0].note}
    
              </Typography>
            </Box>
          </Modal>
        </div>
      );







}
}