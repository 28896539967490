import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from "axios"

import DiscountIcon from '@mui/icons-material/Discount';





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
    height: "50vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function CSDiscountModal( { orderDetails, ticketId, setRefineContent }) {

    const [open, setOpen] = useState(false);
    const [refunds, setRefunds] = useState([]);
    const [percent, setPercent] = useState("");
    const [refundAmount, setRefundAmount] = useState("0");



    const handleOpen = () => {
        setOpen(true)
        handleLog()
    }

    const handleClose = () => {
        setOpen(false)
        setRefundAmount("0")
        setPercent("")
    }

    const handleLog = () => {

        console.log(orderDetails)
        getDiscounts()
    }


    const handleRefundAmount = (e) => {
        setRefundAmount(e.target.value)
    }

    const calcPercent = () => {

        setRefundAmount(((percent * 0.01) * orderDetails.orders[0].subtotal_price).toFixed(2))
    }


    const handlePercent = (e) => {
        setPercent(e.target.value)
    }



    const getDiscounts = async () => {
        const response  = await axios.post("/api/shopify/getdiscounts",
            {
                id: orderDetails.orders[0].id
            }
        )

        console.log(response.data)

        setRefunds(response.data.refunds)
    }


    const calculateRefund = async () => {


        const payload = {

            refund: {
                note: "Lyonette",
                // transactions: [
                //     {
                //     kind: "refund", // Indicates this is a refund
                //     gateway: orderDetails.orders[0].payment_gateway_names[0], // Specify the payment gateway used for the original transaction
                //     amount: "50.00" // The custom refund amount
                //     }
                // ]


            }
        }
       

        const response = await axios.post("/api/shopify/calculaterefund",
            {
                id: orderDetails.orders[0].id,
                payload: payload
            }
        )


        console.log(response.data)


        const obj = response.data


        obj.refund.notify = true
        obj.refund.note = `Lyonette -- Ticket: #${ticketId}`
        obj.refund.transactions[0].amount = refundAmount
        obj.refund.transactions[0].kind = "refund"


        console.log(obj)


        sendRefund(obj)



    }




    const sendRefund = async (payload) => {

        const contextMessage = "I have applied that discount, you should recieve a confirmation shortly, please allow 1-2 days for the refund to be processed"


        const response = await  axios.post("/api/shopify/sendrefund",
            {
                payload: payload,
                id: orderDetails.orders[0].id
            }
        )


        console.log(response.data)

        if (response.status === 200) {
            alert("Refund success")
            setRefineContent(contextMessage)
            handleClose()
        }
    }




    return (
        <div>
        <DiscountIcon
            sx={{color: "#27487d", fontSize: "2vw", cursor:"pointer", margin:"10px" }}
            onClick={handleOpen}
        />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {orderDetails.orders ?
            <Box sx={style}>
                <h5>ID: {orderDetails.orders[0].id}</h5>
                <h5>Created At: {orderDetails.orders[0].created_at}</h5>
                <h5>Subtotal: {orderDetails.orders[0].subtotal_price}</h5>
                <h5>Discount Amount: {orderDetails.orders[0].total_discounts}</h5>
                <h5>Shipping Cost: {orderDetails.orders[0].shipping_lines[0].price}</h5>
                <h5>Refunds: {(refunds.length > 0) ? "Refunded" : "Not Refunded" }</h5>
                <input value={percent} onChange={handlePercent} type="number" min="0" max="100"></input>
                <button onClick={calcPercent}>Calculate Percent</button>
                <input value={refundAmount} onChange={handleRefundAmount}></input>
                
            <button onClick={handleLog}>LOG</button>
            <button onClick={calculateRefund}>calculate</button>
            </Box>
            :
            <Box>
                <h6> No Orders Found</h6>
            </Box>
            }
          </Modal>
        </div>
      );







}
