import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageIcon from '@mui/icons-material/Image';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '70vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export default function CSImageModal({ imageData }) {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (imageData.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageData.length);
    }
  };

  const handlePrevious = () => {
    if (imageData.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex - 1 + imageData.length) % imageData.length
      );
    }
  };

  return (
    <div>
      <ImageIcon
        sx={{ color: '#27487d', fontSize: '2vw', cursor: 'pointer', margin: '10px' }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {imageData.length > 0 && (
            <>
              <img
                src={imageData[currentImageIndex]}
                alt={`Image ${currentImageIndex + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '16px',
                  color: '#000',
                }}
              >
                {`${currentImageIndex + 1} / ${imageData.length}`}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handlePrevious} sx={{ color: '#000' }}>
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton onClick={handleNext} sx={{ color: '#000' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}