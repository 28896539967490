import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AssessmentIcon from '@mui/icons-material/Assessment';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SettingsIcon from '@mui/icons-material/Settings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ImageIcon from '@mui/icons-material/Image';
import NumbersIcon from '@mui/icons-material/Numbers';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { useAuthStatus } from '../hooks/authStatus';

//FB
import { auth } from '../services/firebase';
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';




import { useNavigate, useLocation } from "react-router-dom"


const drawerWidth = 200;

const settings = [ 'Logout'];

export default function PermanentDrawerLeft() {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [user, setUser] = useState("");

    const auth = getAuth();

    const location = useLocation();

    useEffect(() => {
     
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user.email)
          setUser(user.email[0].toUpperCase())
          // if (user.email === "jacob@pacas.com") {
            setTheme()
          // }

        } else {
          console.log("no")
          navigate("/login")
        }
      });
    }, []);

    const setTheme = () => {

      const root = document.documentElement;
      root.style.setProperty('--primary-color', '#505152');
      root.style.setProperty('--secondary-color', '#303030');
      root.style.setProperty('--content-color', '#0d0c0c');
      root.style.setProperty('--text-color', '#e8e8e8');

    }
  
    // const handleOpenNavMenu = (event) => {
    //   setAnchorElNav(event.currentTarget);
    // };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleSignOut = () => {
      signOut(auth).then(() => {
          // Sign-out successful.
          navigate("/login")
        }).catch((error) => {
          // An error happened.
        });
  }
  
    // const handleCloseNavMenu = () => {
    //   setAnchorElNav(null);
    // };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
      
    };

    let navigate = useNavigate()

    let currentRoute = location.pathname
    console.log(currentRoute)
      if (location.pathname === "/bom") {currentRoute = "Boms & Translations"}
      else if (location.pathname === "/packcreator") {currentRoute = "Pack Creator"}
      else if (location.pathname === "/bulkcreation") {currentRoute = "Bulk Creation"}
      else if (location.pathname === "/") {currentRoute = "Dashboard"}
      else if (location.pathname === "/labels") {currentRoute = "Labels"}
      else if (location.pathname === "/kr") {currentRoute = "Westmont Inventory"}
      else if (location.pathname === "/adjustments") {currentRoute = "Adjustments"}
      else if (location.pathname === "/bundleimages") {currentRoute = "Bundle Images"}
      else if (location.pathname === "/upc") {currentRoute = "UPC"}
      else if (location.pathname === "/colorchanger") {currentRoute = "Settings"}
      else if (location.pathname === "/alerts") {currentRoute = "Alerts & Reports"}
      else if (location.pathname === "/customersupport") {currentRoute = "Customer Support"}


      if (user) {

    
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="static" style={{ background: 'transparent', border: "1px solid white", borderRadius: "15px", marginLeft: "14vw", marginTop: "10px", maxWidth: "83vw", height: "7vh"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
       
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              color="inherit"
            >
             
            </IconButton>
         
          </Box> */}
    
        

          <Box style={{display:"flex"}}sx={{ flexGrow: 0 }}>
            <div style={{ width: "40vw"}}>
            <h1 style={{marginTop: "0.5vw",}}>{currentRoute}</h1>
            </div>
            <div>
            <Tooltip title="Open settings">
             
              <IconButton onClick={handleOpenUserMenu} style={{marginLeft: "36vw", marginTop: "1.2vh"}}sx={{ p: 0 }}>
                <Avatar >{user}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleSignOut}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
      <Drawer
      // style={{ marginTop: '1vh'}}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'transparent',
            boxShadow: 'none',
            borderWidth: 0,
            border: "none",
          },
          // marginTop: "50vh"
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar   style={{ marginTop: '5vh'}} />
        <List>

            <ListItem key="dashboard" disablePadding>
              <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon>
                 <DashboardIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            {/* <ListItem key="activity" disablePadding>
              <ListItemButton onClick={() => navigate("/activity")}>
                <ListItemIcon>
                 <ReceiptLongIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }}primary="Activity" />
              </ListItemButton>
            </ListItem> */}

            <ListItem key="pc" disablePadding>
              <ListItemButton onClick={() => navigate("/packcreator")}>
                <ListItemIcon>
                 <ConstructionIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Pack Creator" />
              </ListItemButton>
            </ListItem>
            <ListItem key="bom" disablePadding>
              <ListItemButton onClick={() => navigate("/bom")}>
                <ListItemIcon>
                 <SubtitlesIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Boms / Translations" />
              </ListItemButton>
            </ListItem>
            <ListItem key="bulkcreation" disablePadding>
              <ListItemButton onClick={() => navigate("/bulkcreation")}>
                <ListItemIcon>
                 <ConstructionIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Bulk Creation" />
              </ListItemButton>
            </ListItem>

            <ListItem key="alerts" disablePadding>
              <ListItemButton onClick={() => navigate("/alerts")}>
                <ListItemIcon>
                 <SummarizeIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Alerts & Reports" />
              </ListItemButton>
            </ListItem>

            {/* <ListItem key="analytics" disablePadding>
              <ListItemButton onClick={() => navigate("/analytics")}>
                <ListItemIcon>
                 <AssessmentIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Analytics" />
              </ListItemButton>
            </ListItem> */}
            {/* <ListItem key="analytics" disablePadding>
              <ListItemButton onClick={() => navigate("/analyticsgrid")}>
                <ListItemIcon>
                 <AssessmentIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Analytics Grid" />
              </ListItemButton>
            </ListItem> */}
            <ListItem key="labels" disablePadding>
              <ListItemButton onClick={() => navigate("/labels")}>
                <ListItemIcon>
                
                 <LocalShippingIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Capacity Labels" />
              </ListItemButton>
            </ListItem>

            {/* <ListItem key="fulfillment" disablePadding>
              <ListItemButton onClick={() => navigate("/fulfillment")}>
                <ListItemIcon>
                 <TakeoutDiningIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText    sx={{ color: "white" }} primary="Fulfillment" />
              </ListItemButton>
            </ListItem> */}

            <ListItem key="inventory" disablePadding>
              <ListItemButton onClick={() => navigate("/kr")}>
                <ListItemIcon>
                 <ShoppingBagIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Westmont Inventory" />
              </ListItemButton>
            </ListItem>
            {/* <ListItem key="catalog" disablePadding>
              <ListItemButton onClick={() => navigate("/catalog")}>
                <ListItemIcon>
                 <ImportContactsIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Catalog" />
              </ListItemButton>
            </ListItem> */}
            {/* <ListItem key="yarn" disablePadding>
              <ListItemButton onClick={() => navigate("/yarn")}>
                <ListItemIcon>
                 <WarehouseIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Yarn" />
              </ListItemButton>
            </ListItem> */}
            <ListItem key="adjustments" disablePadding>
              <ListItemButton onClick={() => navigate("/adjustments")}>
                <ListItemIcon>
                 <WarehouseIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Adjustments" />
              </ListItemButton>
            </ListItem>
            <ListItem key="bi" disablePadding>
              <ListItemButton onClick={() => navigate("/bundleimages")}>
                <ListItemIcon>
                 <ImageIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Bundle Images" />
              </ListItemButton>
            </ListItem>
            <ListItem key="upc" disablePadding>
              <ListItemButton onClick={() => navigate("/upc")}>
                <ListItemIcon>
                 <NumbersIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="UPC" />
              </ListItemButton>
            </ListItem>

            <ListItem key="customersupport" disablePadding>
              <ListItemButton onClick={() => navigate("/customersupport")}>
                <ListItemIcon>
                 <SupportAgentIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Customer Support" />
              </ListItemButton>
            </ListItem>
            <ListItem key="settings" disablePadding>
              <ListItemButton onClick={() => navigate("/colorchanger")}>
                <ListItemIcon>
                 <SettingsIcon sx={{ color: "var(--content-color)", bgcolor: "white", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                <ListItemText  sx={{ color: "white" }} primary="Settings" />
              </ListItemButton>
            </ListItem>
   
        </List>

      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
       
      </Box>
    </Box>
  );

} 

else {
  return (
<></>
 
  )
}


}