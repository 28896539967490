import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import Papa from "papaparse"
import JsBarcode from 'jsbarcode';
import nameMaster from "../assets/nameMaster4.json"

export default function YarnTabOne({ inboundData, multiChecked, handleOne }) {
  const [base64Images, setBase64Images] = useState([]);
  const [data, setData] = useState([]);
  const [po, setPo] = useState("HC1013MNW");
  const [ro, setRo] = useState("4360393");
  const [quantity, setQuantity] = useState("40");



  // let sku = "1-3333X1-4444X1-6666X1-9999X1-2222X1-22222"
  let weight = "8.85 KG"
  // let upc = "810118930423"
  // let po = "PACA-92823"
  // let quantity = "30"
  let dim = "22 x 15 x 10.5 (in)"


  //FILE UPLOAD
  const handleFileUpload = (e) => {
    let currentTime = new Date().toLocaleString()
    console.log(currentTime)
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function(results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map(elem => {
            let foundName = nameMaster.find((elm) => elm.SKU === elem.sku)

            if (foundName) {

            
           
  
            return (
              {
                sku: elem.sku,
                upc: elem.upc,
                des: elem.des,
                price: elem.price
              }
            )
            } else {
                return (
                    {
                        sku: elem.sku,
                        upc: elem.upc,
                        des: elem.des,
                        price: elem.price

                    }
                )
            }
  
      
          })
          console.log(formArray)
          setData(formArray)
        }}
      )
    }
  
  }
  const createBarcode = (upc) => {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 150;
    console.log("TEST")
  
    JsBarcode(canvas, upc, {format: "UPC"});
    return canvas.toDataURL("image/png");
  }

  const handleImageLoad = async ({
   sku,
   upc,
   des,
   price
  }) => {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');

    
    // const img = new Image();
    // img.crossOrigin = 'anonymous';
    // console.log("canvas created")

   

    await new Promise((resolve) => {
  
          
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'black';

      // ctx.beginPath();
      // ctx.rect(10, 10, 165, 40);
      // ctx.fill();
      // ctx.stroke();

   
      let barImg = new Image()
      barImg.onload = () => {
        ctx.drawImage(barImg, 30, 30, 250, 170)

        ctx.font = "15px Helevetica";
        ctx.fillStyle = "black";

        //DES
        // ctx.fillText("DES: ",40,70)

        // const price = "$17.00"
        ctx.fillText(price ?? "",130,20)
        ctx.fillText(des ?? "",20,35)


        ctx.lineWidth = 2.5;

        resolve();
      

      }
   


          
        

    //   ctx.fillStyle = hex;
    //   ctx.fillRect(540, 0, canvas.width, canvas.height);

        
        // img.src = data;
        barImg.src = createBarcode(upc)
    });

    const dataURL = canvas.toDataURL('image/png');

    return dataURL;
  };

  const handleMultiple = async () => {
    const filteredArray = data.reduce((uniqueArray, currentObj) => {
      const isDuplicate = uniqueArray.some(obj => obj.sku === currentObj.sku);
      if (!isDuplicate) {
        uniqueArray.push(currentObj);
      }
      return uniqueArray;
    }, []);

    let filterFinal = [] 
    if (multiChecked === true) {
      filterFinal = filteredArray
    } else {
      filterFinal = data
    }
    const promises = filterFinal.map(handleImageLoad);
    const base64Array = await Promise.all(promises);

    setBase64Images(base64Array);
  };

  const handleDownload = async () => {
    const zip = new JSZip();
  
    for (let i = 0; i < base64Images.length; i++) {
      const image = new Image();
      image.src = base64Images[i];
      await new Promise(resolve => {
        image.onload = resolve;
      });
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
  
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [1.5, 1],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 1.5, 1);
      const pdfBlob = pdf.output('blob');
  
      zip.file("INDIVIDUAL_" + data[i].sku + ".pdf", pdfBlob);
    }

    const date = new Date();
    const currentTime = "IND_" + (date.getMonth() + 1).toString() + '_' + 
                        date.getDate().toString() 
                     
  
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${currentTime}.zip`;
      link.click();
    });
  };

  
  
    useEffect(() => {
        console.log("fire")
      handleMultiple();
    }, [data]);
  
    return (
        <div style={{marginLeft: "200px"}}>
           <button onClick={() => handleOne()}>Back</button>
            <div style={{display:"flex", marginLeft: "5vw"}}>
          <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
        />
      {data.length > 0 && <button style={{marginLeft:"40vw"}} onClick={handleDownload}>Download</button>}
</div>
      <div style={{ height: "100vh", overflowY: "scroll",marginBottom: "40px" } } className="pack-display">
          {/* <button onClick={handleDownload}>Download</button> */}
          <br></br>
          {base64Images.map((base64Image, index) => (
          <img key={index} src={base64Image} style={{maxWidth: "600px", border: "2px solid black", marginLeft: "5vw", backgroundColor: "white", }} alt={`Image ${index+1}`} />
      ))}
  
      </div>
      </div>
  )
  }