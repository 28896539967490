import React, { useRef, useEffect, useState } from 'react';

//Vectors
import snow1 from "../assets/buttons/vectors/snow1.png"
import snow2 from "../assets/buttons/vectors/snow2.png"
import paca from "../assets/buttons/vectors/paca.png"
import paca2 from "../assets/buttons/vectors/paca2.png"

function ComplexCircle({ photoData, details, buttonPass }) {
  const canvasRef = useRef(null);
  const [b64String, setb64String] = useState("")

  const handleDownload = () => {

    const link = document.createElement('a');
    link.download = details.sku1;
    link.href = b64String; 
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link); 
  };



  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const pixelRatio = 2;
    canvas.width = 200 * pixelRatio;
    canvas.height = 200 * pixelRatio;
    canvas.style.width = '200px';
    canvas.style.height = '200px';

    const slices = photoData.length;

    // Set the circle's dimensions and center
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;

    const width2 = canvas.width * 0.95;
    const height2 = canvas.height * 0.95;
    const centerX2 = width / 2;
    const centerY2 = height / 2;

    // Calculate the radius based on the smallest dimension
    const radius = Math.min(width, height) / 2 - 10;
    const radius2 = Math.min(width2, height2) / 2 - 10;

    let aug = 1.5708;
    let sliceCount = 1;

    if (photoData.length === 2) {
        sliceCount = 2;
    } else if (photoData.length === 3) {
        sliceCount = 1.19;
        aug = 1.09;
    }
// Draw the secondary circle as a ring (annulus)
for (let i = 0; i < slices; i++) {
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    const angleStart = ((i / slices) * 2 * Math.PI) + Math.PI / sliceCount - aug;
    const angleEnd = (((i + 1) / slices) * 2 * Math.PI) + Math.PI / sliceCount + 0.01 - aug;
    const color = photoData[i].secondary;

    ctx.beginPath();
    // Outer arc (larger radius)
    ctx.arc(centerX, centerY, radius, angleStart, angleEnd, false);
    // Inner arc (smaller radius), drawn in reverse to create a ring
    ctx.arc(centerX, centerY, radius2, angleEnd, angleStart, true);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
}

    // Draw the primary circle without overlaying the secondary
    for (let i = 0; i < slices; i++) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        const angleStart = ((i / slices) * 2 * Math.PI) + Math.PI / sliceCount - aug;
        const angleEnd = (((i + 1) / slices) * 2 * Math.PI) + Math.PI / sliceCount + 0.01 - aug;

        const colors = [photoData[i].primary, photoData[i].primary2, photoData[i].primary3].filter((elem) => elem !== "");

         
        let pValue = 0;  

        if (photoData[i].pattern) {
            pValue = photoData[i].pattern
        }

        if (pValue === 1) {
            const color = photoData[i].primary;

            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
            ctx.beginPath();
            ctx.moveTo(centerX2, centerY2);
            ctx.arc(centerX2, centerY2, radius2, angleStart, angleEnd);
            ctx.fillStyle = color;
            ctx.fill();
            ctx.restore();
        } else if (pValue === 2) {
            const stripeWidth = radius2 / colors.length;

            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area

            for (let j = 0; j < colors.length; j++) {
                const innerRadius = radius2 - j * stripeWidth;
                const outerRadius = innerRadius - stripeWidth;

                ctx.beginPath();
                ctx.moveTo(centerX2, centerY2);
                ctx.arc(centerX2, centerY2, innerRadius, angleStart, angleEnd);
                ctx.arc(centerX2, centerY2, outerRadius, angleEnd, angleStart, true);
                ctx.closePath();
                ctx.fillStyle = colors[j];
                ctx.fill();
            }
            ctx.restore();
        } else if (pValue === 4) {
            const stripeHeight = (2 * radius2) / 18;

            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area

            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area

            for (let j = 0; j < 18; j++) {
                const yPosition = centerY - radius2 + j * stripeHeight;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(centerX - radius2, yPosition, 2 * radius2, stripeHeight);
            }
            ctx.restore();
        }
        else if (pValue === 3) {
            const stripeWidth = radius2 / 12;

            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area

            for (let j = 0; j < 12; j++) {
                const innerRadius = radius2 - j * stripeWidth;
                const outerRadius = innerRadius - stripeWidth;

                let ind = j % colors.length

                ctx.beginPath();
                ctx.moveTo(centerX2, centerY2);
                ctx.arc(centerX2, centerY2, innerRadius, angleStart, angleEnd);
                ctx.arc(centerX2, centerY2, outerRadius, angleEnd, angleStart, true);
                ctx.closePath();
                ctx.fillStyle = colors[ind];
                ctx.fill();
            }
            ctx.restore();
        }

        else if (pValue === 5) {
            const stripeHeight = (2 * radius2) / 6;

            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area

            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area

            for (let j = 0; j < 6; j++) {
                const yPosition = centerY - radius2 + j * stripeHeight;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(centerX - radius2, yPosition, 2 * radius2, stripeHeight);
            }
            ctx.restore();
        }

        else if (pValue === 6) {
            const stripeWidth = (2 * radius2) / 6;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            for (let j = 0; j < 6; j++) {
                const xPosition = centerX - radius2 + j * stripeWidth;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(xPosition, centerY - radius2, stripeWidth, 2 * radius2);
            }
            ctx.restore();
        }

        else if (pValue === 7) {
            const stripeWidth = (2 * radius2) / 18;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            for (let j = 0; j < 18; j++) {
                const xPosition = centerX - radius2 + j * stripeWidth;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(xPosition, centerY - radius2, stripeWidth, 2 * radius2);
            }
            ctx.restore();
        }


        else if (pValue === 8) {
            const stripeSize = (2 * radius2) / 6;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            for (let i = 0; i < 6; i++) {
                for (let j = 0; j < 6; j++) {
                    const xPosition = centerX - radius2 + i * stripeSize;
                    const yPosition = centerY - radius2 + j * stripeSize;
                    let ind = (i + j) % colors.length;
                    ctx.fillStyle = colors[ind];
                    ctx.fillRect(xPosition, yPosition, stripeSize, stripeSize);
                }
            }
            ctx.restore();
        }

        else if (pValue === 9) {
            const stripeSize = (2 * radius2) / 18;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            for (let i = 0; i < 18; i++) {
                for (let j = 0; j < 18; j++) {
                    const xPosition = centerX - radius2 + i * stripeSize;
                    const yPosition = centerY - radius2 + j * stripeSize;
                    let ind = (i + j) % colors.length;
                    ctx.fillStyle = colors[ind];
                    ctx.fillRect(xPosition, yPosition, stripeSize, stripeSize);
                }
            }
            ctx.restore();
        }



        else if (pValue === 10) {
            const stripeSize = (2 * radius2) / 6;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            // Draw horizontal stripes with partial transparency
            ctx.globalAlpha = 0.7; // Set transparency level
            for (let j = 0; j < 6; j++) {
                const yPosition = centerY - radius2 + j * stripeSize;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(centerX - radius2, yPosition, 2 * radius2, stripeSize);
            }
        
            // Draw vertical stripes with partial transparency
            for (let i = 0; i < 6; i++) {
                const xPosition = centerX - radius2 + i * stripeSize;
                let ind = i % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(xPosition, centerY - radius2, stripeSize, 2 * radius2);
            }
        
            ctx.globalAlpha = 1.0; // Reset transparency
            ctx.restore();
        }

        else if (pValue === 11) {
            const stripeSize = (2 * radius2) / 12;
        
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX2, centerY2, radius2, 0, 2 * Math.PI);
            ctx.clip();  // Clip to the secondary circle's area
        
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius2, angleStart, angleEnd);
            ctx.closePath();
            ctx.clip();  // Additional clipping to the primary circle's slice area
        
            // Draw horizontal stripes with partial transparency
            ctx.globalAlpha = 0.7; // Set transparency level
            for (let j = 0; j < 12; j++) {
                const yPosition = centerY - radius2 + j * stripeSize;
                let ind = j % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(centerX - radius2, yPosition, 2 * radius2, stripeSize);
            }
        
            // Draw vertical stripes with partial transparency
            for (let i = 0; i < 12; i++) {
                const xPosition = centerX - radius2 + i * stripeSize;
                let ind = i % colors.length;
                ctx.fillStyle = colors[ind];
                ctx.fillRect(xPosition, centerY - radius2, stripeSize, 2 * radius2);
            }
        
            ctx.globalAlpha = 1.0; // Reset transparency
            ctx.restore();
        }


        let vectorValue = photoData.map((elem) => {
            return (
                elem.vector
            )
        }).filter((elm) => elm !== undefined).sort((a,b) => a - b).filter((ellm) => ellm !== "")


    
        if (vectorValue.length > 0) {

            console.log(vectorValue)

        const img = new Image();
        img.crossOrigin = 'anonymous';

        let vectorImage = ""


        switch(vectorValue[0]) {
            // case 100:
            //     vectorImage = ""
            //   break;
            case 1:
                vectorImage = snow1
              break;
            case 2:
                vectorImage = snow2
            break;
            case 3:
                vectorImage = paca
            break;
            case 4:
                vectorImage = paca2
            break;
          
          }


       
            img.onload = () => {


         
              ctx.drawImage(img, 100, 100, 200, 200);
           




              const downscaledCanvas = document.createElement('canvas');
              downscaledCanvas.width = 200;
              downscaledCanvas.height = 200;
              downscaledCanvas.getContext('2d').drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, downscaledCanvas.width, downscaledCanvas.height);
          
              const dataURL = downscaledCanvas.toDataURL("image/png");
              setb64String(dataURL);
              buttonPass(dataURL);

// downscaledCanvas.width = 200;
// downscaledCanvas.height = 200;

// const ctx2 = downscaledCanvas.getContext('2d');

// // Rotate the canvas 90 degrees clockwise
// ctx2.translate(downscaledCanvas.width / 2, downscaledCanvas.height / 2);
// ctx2.rotate((90 * Math.PI) / 180); // Convert 90 degrees to radians

// // Draw the image on the rotated canvas
// ctx2.drawImage(
// canvas,
// 0,
// 0,
// canvas.width,
// canvas.height,
// -downscaledCanvas.height / 2,
// -downscaledCanvas.width / 2,
// downscaledCanvas.height,
// downscaledCanvas.width
// );

// const dataURL = downscaledCanvas.toDataURL("image/png");
// setb64String(dataURL);
// buttonPass(dataURL);

            };
            img.src = vectorImage;



        }


          

        
    }




        //NORMAL

        const downscaledCanvas = document.createElement('canvas');
        downscaledCanvas.width = 200;
        downscaledCanvas.height = 200;
        downscaledCanvas.getContext('2d').drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, downscaledCanvas.width, downscaledCanvas.height);
    
        const dataURL = downscaledCanvas.toDataURL("image/png");
        setb64String(dataURL);
        buttonPass(dataURL);

        // const downscaledCanvas = document.createElement('canvas');

// // Set the new dimensions for the rotated canvas
// downscaledCanvas.width = 200;
// downscaledCanvas.height = 200;

// const ctx2 = downscaledCanvas.getContext('2d');

// // Rotate the canvas 90 degrees clockwise
// ctx2.translate(downscaledCanvas.width / 2, downscaledCanvas.height / 2);
// ctx2.rotate((90 * Math.PI) / 180); // Convert 90 degrees to radians

// // Draw the image on the rotated canvas
// ctx2.drawImage(
//     canvas,
//     0,
//     0,
//     canvas.width,
//     canvas.height,
//     -downscaledCanvas.height / 2,
//     -downscaledCanvas.width / 2,
//     downscaledCanvas.height,
//     downscaledCanvas.width
// );

// const dataURL = downscaledCanvas.toDataURL("image/png");
// setb64String(dataURL);
// buttonPass(dataURL);



  





    






}, [photoData]);



  return (
    <div style={{ width:"15vw", height:"15vw", marginTop:"4vh"}}>
    <canvas ref={canvasRef} width={200} height={200} />
    <button onClick={handleDownload}>DOWNLOAD</button>
    </div>
  )
}

export default ComplexCircle;